<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>USERS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title class="text-uppercase grey--text">
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM'"> BARU</span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modfied
            </v-chip>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="itemModified.username"
                    :rules="rulesLenght"
                    prepend-inner-icon="mdi-account"
                    label="User Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="itemModified.email"
                    :rules="[validateNotEmpty, validateEmail]"
                    required
                    label="Email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-model="itemModified.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    label="Password"
                    hide-details="auto"
                    @click:append="show1 = !show1"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-switch
                    v-model="itemModified.isAccountNonLocked"
                    :label="
                      itemModified.isAccountNonLocked ? 'Aktif' : 'Locked'
                    "
                    x-small
                    class="pa-3"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    prepend-inner-icon="mdi-phone"
                    v-model="itemModified.phone"
                    label="Phone"
                    prefix="+62 "
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    v-model="itemModified.fullName"
                    label="Nama Lengkap"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.fdivisionBean"
                    :items="itemsFDivision"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    dense
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Dinas/OPD"
                    hint="Dinas/OPD"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.organizationLevel"
                    :items="itemsOrganizationLevelComputed"
                    auto-select-first
                    dense
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Level Organisasi"
                    hint="CORP=Kota/Kab, DIV=Dinas/OPD"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="10" md="10">
                  <v-autocomplete
                    v-model="itemModified.roles"
                    :items="itemsRoles"
                    auto-select-first
                    dense
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Roles"
                    hint="Bisa dipilih multi"
                    persistent-hint
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <close-confirm-dialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></close-confirm-dialog>
    </v-dialog>
  </div>
</template>

<script>
import AuthService from "@/services/auth-service";
import UserService from "@/services/user-service";
import CloseConfirmDialog from "@/components/utils/CloseConfirmDialog";
import User from "@/models/user";
import FormMode from "@/models/form-mode";
import ERole from "@/models/e-role";
import ApiFonnteService from "@/services/apiservices-ext/api-fonnte-service";

export default {
  name: "UsersDialog",
  components: { CloseConfirmDialog },
  props: {
    formMode: String,
  },
  data() {
    return {
      show1: false,
      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new User(),

      selectFDivision: { id: 0, kode1: "", description: "" },
      itemsFDivision: [{ id: 0, kode1: "", description: "" }],
      itemsFSalesman: [],
      itemsRoles: [
        ERole.ROLE_USER,
        ERole.ROLE_ADMIN,
        ERole.ROLE_VERIFIKATOR,
        ERole.ROLE_APPROVAL,
        ERole.ROLE_PUBLIC,
      ],
      itemsOrganizationLevel: ["CORP", "DIV"],

      validateNotEmpty: (value) => !!value || "Email Tidak Boleh Kosong",
      validateEmail: (value) => {
        const emailRegex = /^[a-zA-Z0-9]{2,}@gmail\.com$/;
        return emailRegex.test(value) || "Format Email Salah";
      },
      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 3 || "Harus lebih besar dari 3 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      // console.log(`${defaultItem}`)
      // console.log(`${modifiedItem}`)

      return defaultItem !== modifiedItem;
    },
    itemsOrganizationLevelComputed() {
      let newItemsLevelOrganisasi = [];
      if (this.currentUser.organizationLevel === "DIV") {
        newItemsLevelOrganisasi = ["CORP", "DIV"];
      } else {
        newItemsLevelOrganisasi = this.itemsOrganizationLevel;
      }
      return newItemsLevelOrganisasi;
    },
  },
  watch: {},
  methods: {
    showDialog(selectedIndex, item, itemsFDivision, itemsFSalesman) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        this.itemDefault = new User("", "", "");
        this.itemModified = new User("", "", "");
        this.selectedIndex = -1;
      }
      this.itemsFDivision = itemsFDivision;
      this.itemsFSalesman = itemsFSalesman;
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.formMode === FormMode.EDIT_FORM) {
          //di proses di server -> Jika kosong maka tidak akan dirubah di server
          // this.itemModified.password = ''

          /**
           * Kirim pesan
           */
          AuthService.updateUser(this.itemModified).then(
            () => {
              this.$emit("eventFromFormDialogEdit", this.itemModified);
              if (
                this.itemModified.isAccountNonLocked !==
                this.itemDefault.isAccountNonLocked
              ) {
                // console.log("Kirim Pesan")
                let pesan = "";
                if (this.itemModified.isAccountNonLocked) {
                  pesan =
                    "User telah diaktifkan silahkan login https://silayak.palangkaraya.go.id/login";
                } else {
                  pesan =
                    "Admin telah menonaktifkan user anda, silahkan hubungi admin";
                }
                const simpleMessage = {
                  to: this.itemModified.phone,
                  subject: "User Account",
                  message: pesan,
                };
                ApiFonnteService.postFonnteSendMessagePublic(
                  simpleMessage
                ).then(() => {});
              }
            },
            (error) => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data;
            }
          );
        } else {
          AuthService.register(this.itemModified).then(
            (response) => {
              this.itemModified.password = "Welcome1";
              this.$emit("eventFromFormDialogNew", response.data);
            },
            (error) => {
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        }
      }
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = "";

      UserService.getUserById(item.id).then(
        (response) => {
          // console.log(JSON.stringify(response.data))

          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
          /**
           * Harus di rubah jadi aarray dahulu
           */
          this.itemDefault.roles = this.rolesToArray(this.itemDefault.roles);
          this.itemModified.roles = this.rolesToArray(this.itemModified.roles);
        },
        (error) => {
          console.log(error);
        }
      );
    },

    lookupFSalesman(fsalesmanBean) {
      const str = this.itemsFSalesman.filter((x) => x.id == fsalesmanBean);
      if (str.length > 0) {
        return `${str[0].spcode} - ${str[0].spname} `;
      } else {
        return "-";
      }
    },
    rolesToArray(roles) {
      var arrRoles = [];
      roles.forEach((role) => {
        arrRoles.push(role.name);
      });
      return arrRoles;
    },
  },
};
</script>

<style scoped>
</style>